"use client";
import React, { FC, useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import Image from "next/image";
import Link from "next/link";
import { Icon } from "@iconify/react";

import { GQL_GET_FUNDRAISINGS_BY_USERNAME } from "../../../gqls";
import { IUser } from "@/composables/user.type";
import { IPaymentLinkPage } from "@/composables/paymentlink.type";
import { createInternalPath, handleResizeImageUrl } from "@/utils";
import SecureBadge from "@/components/secure-badge";
import { MayarAlertBase } from "@/components/ui/mayar-alert-base";
import PoweredBy from "@/components/powered-by";
import FundraisingCards from "./components/cards";
import { Button } from "@/components/ui/button";
import Spinner from "@/components/ui/spinner";
// import { setDefaultLocale } from "@/lib/moment";

interface InitialData {
  paymentLinkPageActive: IPaymentLinkPage;
  paymentLinkPageClosed: IPaymentLinkPage;
}
interface FundraisingPageProps {
  user: IUser;
  initialData: InitialData;
}

const FundraisingPage: FC<FundraisingPageProps> = ({ user, initialData }) => {
  const { t } = useTranslation();
  const [pageActive, setPageActive] = useState(1);
  const [pageClosed, setPageClosed] = useState(1);
  const [loadingActiveData, setLoadingActiveData] = useState(false);
  const [loadingClosedData, setLoadingClosedData] = useState(false);
  const [fundarisingActive, setFundraisingActive] = useState(
    initialData.paymentLinkPageActive.items
  );
  const [fundarisingClosed, setFundraisingClosed] = useState(
    initialData.paymentLinkPageClosed.items
  );

  const gparam = {
    page: 1,
    pageSize: 10,
    status: "active",
    username: user.paymeLink,
  };

  const [loadMoreActive] = useLazyQuery(GQL_GET_FUNDRAISINGS_BY_USERNAME, {
    context: { public: true },
  });

  const [loadMoreClosed] = useLazyQuery(GQL_GET_FUNDRAISINGS_BY_USERNAME, {
    context: { public: true },
  });

  const handlePageChangeActive = (page = 1) => {
    setLoadingActiveData(true);
    loadMoreActive({
      variables: { ...gparam, status: "active", page },
    }).then((res: any) => {
      const newFundraisingActive = res.data.getFundraisingsByUsername?.items;
      setFundraisingActive((prev) => [...prev, ...newFundraisingActive]);
      setPageActive(page);
      setLoadingActiveData(false);
    });
  };

  const handlePageChangeClosed = (page = 1) => {
    setLoadingClosedData(true);
    loadMoreClosed({
      variables: { ...gparam, status: "closed", page },
    }).then((res: any) => {
      const newFundraisingClosed = res.data.getFundraisingsByUsername?.items;
      setFundraisingClosed((prev) => [...prev, ...newFundraisingClosed]);
      setPageClosed(page);
      setLoadingClosedData(false);
    });
  };

  return (
    <div className="bg-white">
      <div className="w-full md:w-6/12 mx-auto mt-10 px-4 md:px-0">
        <div className="w-full flex flex-col items-center text-center mb-[15px]">
          <Image
            alt="logo"
            width={80}
            height={80}
            className="rounded-full h-[80px]"
            src={
              handleResizeImageUrl(user?.account?.logo?.url, 300) ||
              process.env.NEXT_PUBLIC_DEFAULT_USER_AVATAR
            }
          />
          <h2 className="mt-[10px] flex items-center gap-2 text-xl">
            {user.account.name}
            {user.account.isVerified && (
              <Image
                width={17}
                height={17}
                src="/check.png"
                alt="Verified account"
              />
            )}
          </h2>
        </div>
        <div className="text-center mb-[15px]">
          <Link href={createInternalPath("/donate-now", user.paymeLink)}>
            <Button
              variant="primary"
              className="text-sm font-medium uppercase w-full "
              size="lg"
            >
              {t("donation.general")}
            </Button>
          </Link>
        </div>
        <div className="text-center mb-5">
          <div className="w-full">
            <MayarAlertBase
              variant="light"
              isCloseable={false}
              className="flex flex-col items-center leading-6"
            >
              <p>{user.account.profile || "-"}</p>
              <hr className="my-3.5 w-full" />
              <span className="flex text-center">
                <Icon
                  width={16}
                  height={16}
                  className="text-[#6c757d] mr-1.5"
                  icon="lucide:home"
                />
                {user.account.address || "-"}
              </span>
              <div className="flex">
                <a
                  href={
                    user.account.email ? "mailto:" + user.account.email : "#"
                  }
                  className="text-blue-500 flex items-center"
                >
                  <Icon
                    width={16}
                    height={16}
                    icon="ic:outline-mail"
                    className="mr-1.5"
                  />{" "}
                  {user.account.email || "-"}
                </a>
                &nbsp; &nbsp;
                <a
                  href={
                    user.account.mobile ? "tel:" + user.account.mobile : "-"
                  }
                  className="text-blue-500 flex items-center"
                >
                  <Icon
                    width={16}
                    height={16}
                    icon="carbon:phone"
                    className="mr-1.5"
                  />{" "}
                  {user.account.mobile || "-"}
                </a>
              </div>
            </MayarAlertBase>
          </div>
        </div>
        <div className="text-center w-full mb-5">
          <h3 className="text-lg mb-5 font-semibold">
            {t("fundraising.title2")}
          </h3>
          <FundraisingCards paymentLinks={fundarisingActive} />
          {initialData.paymentLinkPageActive.pageSize <
            initialData.paymentLinkPageActive.total && (
            <div className="text-center mt-5">
              <Button
                variant="light"
                size="sm"
                onClick={() => handlePageChangeActive(pageActive + 1)}
                className="uppercase text-xs min-w-[100px]"
                disabled={loadingActiveData}
              >
                {loadingActiveData ? <Spinner /> : t("paymentLink.showMore")}
              </Button>
            </div>
          )}
        </div>

        {fundarisingClosed?.length > 0 && (
          <div className="text-center w-full">
            <h3 className="text-lg mb-5 font-semibold">
              {t("fundraising.complete")}
            </h3>
            <FundraisingCards paymentLinks={fundarisingClosed} />
            {initialData.paymentLinkPageClosed.pageSize <
              initialData.paymentLinkPageClosed.total && (
              <div className="text-center mt-5">
                <Button
                  variant="light"
                  size="sm"
                  onClick={() => handlePageChangeClosed(pageClosed + 1)}
                  className="uppercase text-xs min-w-[100px]"
                  disabled={loadingClosedData}
                >
                  {loadingClosedData ? <Spinner /> : t("paymentLink.showMore")}
                </Button>
              </div>
            )}
          </div>
        )}

        <SecureBadge />

        <MayarAlertBase
          variant="light"
          className="mt-5 leading-6"
          isCloseable={false}
        >
          {t("zakat.disclaimer")}{" "}
          <a href="mailto:bantuan@mayar.id" className="text-blue-500">
            {t("word.report")}
          </a>
        </MayarAlertBase>
      </div>
      <PoweredBy className="pb-5" />
    </div>
  );
};

export default FundraisingPage;
