"use client";
import React, { FC, useEffect } from "react";
import { get } from "lodash";
import Link from "next/link";
import { useTranslation } from "react-i18next";
import { FundraisingCardsV2 } from "./components/cards";
import { IUser } from "@/composables/user.type";
import { IPaymentLinkPage } from "@/composables/paymentlink.type";
import { MayarAlertBase } from "@/components/ui/mayar-alert-base";
import SecureBadge from "@/components/secure-badge";
import PoweredBy from "@/components/powered-by";
import { Button } from "@/components/ui/button";
import FundraisingGallery from "./components/gallery";
import FundraisingNavMenu from "@/components/fundraising/nav-menu";
// import { setDefaultLocale } from "@/lib/moment";
import { createInternalPath } from "@/utils";

interface InitialData {
  paymentLinkPageActive: IPaymentLinkPage;
  paymentLinkPageClosed: IPaymentLinkPage;
}

interface FundraisingPageV2Props {
  user: IUser;
  initialData: InitialData;
}

const FundraisingPageV2: FC<FundraisingPageV2Props> = ({
  user,
  initialData,
}) => {
  const { t } = useTranslation();
  const fundraisingActive = get(
    initialData.paymentLinkPageActive,
    "items",
    []
  );
  const fundraisingClosed = get(
    initialData.paymentLinkPageClosed,
    "items",
    []
  );
  const totalActiveFundraising = get(
    initialData.paymentLinkPageActive,
    "total",
    0
  );

  return (
    <div className="bg-[#f8f9fa] min-h-screen">
      <div className="sm:max-w-[480px] min-h-screen mx-auto bg-white h-full px-4 flex flex-col gap-5 pb-[64px]">
        <FundraisingGallery />

        {fundraisingActive?.length > 0 && (
          <div className="text-center">
            <h2 className="mb-4 font-semibold text-lg">
              {t("fundraising.newFund")}
            </h2>

            <FundraisingCardsV2 paymentLinks={fundraisingActive} />

            {initialData.paymentLinkPageActive.pageSize <
              totalActiveFundraising && (
              <div className="text-center mt-5">
                <Link
                  href={createInternalPath(
                    "/galang-dana/v2/campaign",
                    user.paymeLink
                  )}
                >
                  <Button variant="light" className="text-[11px] uppercase">
                    {t("global.viewAllProgram")}
                  </Button>
                </Link>
              </div>
            )}
          </div>
        )}

        {fundraisingClosed?.length > 0 && (
          <div className="text-center">
            <h2 className="mb-4 font-semibold text-lg">
              {t("fundraising.complete")}
            </h2>

            <FundraisingCardsV2 paymentLinks={fundraisingClosed} />
          </div>
        )}

        <SecureBadge className="mt-0" classNameLanguage="mt-0" />
        <MayarAlertBase
          variant="light"
          isCloseable={false}
          className="mt-0 leading-6"
        >
          {t("zakat.disclaimer")}{" "}
          <a href="mailto:bantuan@mayar.id" className="text-blue-500">
            {t("word.report")}
          </a>
        </MayarAlertBase>
        <PoweredBy className="pb-5 mt-0" />
      </div>
      <FundraisingNavMenu />
    </div>
  );
};

export default FundraisingPageV2;
