"use client";

import React, { FC, useEffect, useState } from "react";
import "../style.scss";
import { debounce } from "lodash";
import { useQuery } from "@apollo/client";
import { useSearchParams } from "next/navigation"; // For managing URL
import { useTranslation } from "react-i18next";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import { Input } from "@/components/ui/input";
import { Icon } from "@iconify/react";
import {
  ProductAnnouncementType,
  PublicAnnouncementType,
} from "@/common/types/public-announcement.type";
import {
  productOptionsHeader,
  updateFetchMore,
  capitalizeFirstLetter,
} from "@/utils";
import { cn } from "@/lib/utils";
import { IUser } from "@/composables/user.type";
import { GET_TAGS_PRODUCTS } from "@/gqls";
import { ITag } from "@/composables/tag.type";

type NavbarTypeType = {
  publicAnnouncement: PublicAnnouncementType;
  user: IUser;
};

type GparamPageType = {
  page: number;
  pageSize: number;
  userId?: string;
  search: {
    status: { operator: string; value: string }[];
    userId?: { operator: string; value: string }[];
  };
};

const defaultValueParam: GparamPageType = {
  page: 1,
  pageSize: 10,
  search: {
    status: [{ operator: "eq", value: "ACTIVE" }],
  },
};

let gparam: GparamPageType = { ...defaultValueParam };

const NavbarType: FC<NavbarTypeType> = ({ publicAnnouncement, user }) => {
  const { t } = useTranslation();
  const searchParams = useSearchParams();
  const queryType = searchParams?.get("type") || "";
  const querySearch = searchParams?.get("search") || "";

  const [activeTag, setActiveTag] = useState<string>("");
  const [tags, setTags] = useState<ITag[]>([]);
  const [displayFilterTag, setDisplayFilterTag] = useState<boolean>(false);

  gparam.userId = user.id;
  gparam.search.userId = [{ operator: "eq", value: user.id }];

  const { data, loading, error, fetchMore } = useQuery(GET_TAGS_PRODUCTS, {
    variables: {
      page: 1,
      pageSize: 10,
      userId: user.id,
      search: {
        status: [{ operator: "eq", value: "ACTIVE" }],
        userId: [{ operator: "eq", value: user.id }],
      },
    },
    context: { public: true },
    fetchPolicy: "network-only",
    skip: !user.id,
  });

  const loadMoreTags = () => {
    gparam.page++;

    // @ts-ignore
    updateFetchMore(fetchMore, gparam, "getListTagsProducts");
  };

  useEffect(() => {
    return () => {
      gparam = { ...defaultValueParam };
      setTags([]);

      const params = new URLSearchParams(window.location.search);
      params.delete("tag");
      const url = `${window.location.pathname}${params && `?${params.toString()}`}`;
      window.history.replaceState(null, "", url);
    };
  }, []);

  useEffect(() => {
    const tagsData = data?.getListTagsProducts.tags || [];

    setTags((prevState) => [...prevState, ...tagsData]);
  }, [data]);

  const handleTypeProduct = (type: string | undefined) => {
    const params = new URLSearchParams(window.location.search);
    params.delete("type");
    if (type) params.set("type", type);

    const url = `${window.location.pathname}?${params.toString()}`;
    window.history.replaceState(null, "", url);
  };

  const _handleSearchInput = (value: string) => {
    const searchQuery = value;
    // Update URL with the search query
    const params = new URLSearchParams(window.location.search);
    params.delete("search");

    if (searchQuery) params.set("search", searchQuery);

    const url = `${window.location.pathname}?${params.toString()}`;
    window.history.replaceState(null, "", url);
  };

  const handleSearchInput = debounce(_handleSearchInput, 500);

  const handleFilterTag = () => {
    setDisplayFilterTag(!displayFilterTag);
    const params = new URLSearchParams(window.location.search);

    if (params.get("tag")) params.delete("tag");

    const url = `${window.location.pathname}${params && `?${params.toString()}`}`;
    window.history.replaceState(null, "", url);
  };

  const handleChangeTag = (tag: ITag) => {
    const params = new URLSearchParams(window.location.search);

    if (tag.id === activeTag) {
      params.delete("tag");
      setActiveTag("");
    } else {
      params.set("tag", tag.name);
      setActiveTag(tag.id);
    }

    const url = `${window.location.pathname}${params && `?${params.toString()}`}`;
    window.history.replaceState(null, "", url);
  };

  return (
    <div>
      <div
        className={cn(
          "lg:min-w-[900px] w-full overflow-hidden my-5 mx-auto bg-gray-50 rounded md:flex md:flex-row md:gap-2 text-[10px] md:justify-between container-product-catalog",
          publicAnnouncement?.product?.length <= 2 ? "flex flex-row gap-2" : ""
        )}
      >
        <div className="flex items-center overflow-x-auto">
          <Button
            variant={!queryType ? "primary" : "ghost"}
            className={cn(
              "text-[13px] font-[600] px-[15px] py-[9px] m-[7px] rounded"
            )}
            onClick={() => handleTypeProduct(undefined)}
          >
            Semua
          </Button>
          {publicAnnouncement?.product
            ?.map((item: ProductAnnouncementType) => ({
              ...item,
              label: productOptionsHeader.find(
                (option) => option.value === item.value
              )?.label,
            }))
            .map((item, index) => {
              if (index < 3 || queryType === item.value) {
                return (
                  <Button
                    key={item.value}
                    variant={queryType === item.value ? "primary" : "ghost"}
                    className={cn(
                      "text-[13px] font-[600] px-[15px] py-[9px] m-[7px] rounded"
                    )}
                    onClick={() => handleTypeProduct(item.value)}
                  >
                    {item.label}
                  </Button>
                );
              }
            })}

          <Popover>
            <PopoverTrigger asChild>
              <Button
                variant="ghost"
                className="text-[13px] text-gray-500 font-[600] px-[15px] py-[9px] m-[7px]"
              >
                Lainnya
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-80 bg-white flex flex-col text-gray-500">
              {publicAnnouncement?.product
                ?.map((item: ProductAnnouncementType) => ({
                  ...item,
                  label: productOptionsHeader.find(
                    (option) => option.value === item.value
                  )?.label,
                }))
                .map((item, index) => {
                  if (index >= 3) {
                    return (
                      <Button
                        key={item.value}
                        variant={queryType === item.value ? "primary" : "ghost"}
                        className={cn("text-[13px] px-[15px] py-[9px] m-[7px]")}
                        onClick={() => handleTypeProduct(item.value)}
                      >
                        {item.label}
                      </Button>
                    );
                  }
                })}
            </PopoverContent>
          </Popover>
        </div>
        <div className="flex items-center gap-7 m-3 justify-end">
          <Icon
            icon="qlementine-icons:filter-16"
            className="text-lg cursor-pointer font-bold"
            onClick={handleFilterTag}
          />
          <div className="relative">
            <Input
              placeholder="Cari produk..."
              className="input-search h-9 text-[13px] placeholder:text-gray-400"
              onChange={(e) => handleSearchInput(e.target.value)}
            />
            <Icon
              icon="ri:search-line"
              className="absolute text-[1rem] text-gray-400 right-2 top-[0.65rem]"
            />
          </div>
        </div>
      </div>
      {displayFilterTag && (
        <div className="my-5 w-full bg-gray-50 rounded container-product-catalog">
          <div className="w-full flex items-center gap-3 py-[13px] px-[27px] sm:overflow-hidden overflow-x-auto">
            <div className="text-[13px] font-[600] font-open-sans">
              {t("public.tagTitle")}:{" "}
            </div>
            {tags?.map((tag) => {
              return (
                <Badge
                  key={tag.id}
                  variant={activeTag === tag.id ? "primary" : "default"}
                  onClick={() => handleChangeTag(tag)}
                  className={cn(
                    "text-[13px] font-[700] cursor-pointer shrink-0",
                    activeTag !== tag.id &&
                      "bg-[#e7f1fd] text-[#007bff] hover:bg-[#007bff] hover:text-white"
                  )}
                >
                  {capitalizeFirstLetter(tag.name)}
                </Badge>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default NavbarType;
