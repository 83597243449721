"use client";
import React from "react";
import {
  Carousel,
  CarouselContent,
  CarouselIndicators,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import Autoplay from "embla-carousel-autoplay";
import Image from "next/image";
import { IImage } from "@/composables/image.type";
import { cn, imageLoader } from "@/lib/utils";
// import useEmblaCarousel, { EmblaCarouselType } from 'embla-carousel-react'
// import { CarouselApi } from "@/components/ui/carousel";
type MultipleImagesType = {
  multipleImage: IImage[];
  notRoundedImage?: boolean;
  customRoundedClass?: string;
};

const MultipleImages = ({
  multipleImage = [],
  notRoundedImage,
  customRoundedClass = "rounded-[5px]",
  ...props
}: MultipleImagesType) => {
  return (
    // aspect ratio to improve CLS on lighthouse peformance audit
    <div
      {...props}
      // className="form rui-sign-form rui-sign-form-cloud p-0 w-full mx-auto"
      // className="form p-0 w-full mx-auto max-w-[400px] bg-white"
      className={cn(
        "w-full mx-auto bg-[#FFF]",
        !notRoundedImage && customRoundedClass
      )}
    >
      <div className="mx-auto">
        <Carousel
          plugins={[
            Autoplay({
              delay: 5000,
            }),
          ]}
          className="!m-0"
        >
          <CarouselContent className="!m-0 mx-auto">
            {multipleImage?.map(
              ({ url, updatedAt, width, height }: IImage, index) => (
                <CarouselItem
                  key={index}
                  index={index}
                  className={cn(
                    "m-0 overflow-visible",
                    !notRoundedImage && customRoundedClass
                  )}
                >
                  {/* <picture> */}
                  {/* <source media="(max-width:699px)" srcSet={url} type="image/webp" /> 
                    <source media="(max-width:640px)" srcSet={url} type="image/webp" /> */}
                  {/* <img
                      id="product-images-mayar"
                      alt={`Cover product`}
                      src={url}
                      // src={`https://static.mayar.shop/_next/image/?url=${url}?w=${width}&q=${50}`}
                      className={cn("mx-auto", !notRoundedImage && 'customRoundedClass')}
                      width={width}
                      height={height}
                      style={{
                        width: "100%",
                        height: "auto",
                        aspectRatio: width / height,
                        objectFit: "cover",
                      }}
                      // loading={index === 0 ? "eager" : "lazy"}
                    /> */}
                  <Image
                    id="product-images-mayar"
                    src={url}
                    alt={`Cover product`}
                    className={cn(
                      "mx-auto",
                      !notRoundedImage && customRoundedClass
                    )}
                    width={width}
                    height={height}
                    style={{
                      width: "100%",
                      height: "auto",
                      aspectRatio: width / height,
                      objectFit: "cover",
                    }}
                    quality={50}
                    // priority={index === 0}
                    priority={true}
                    // loader={imageLoader}
                    // loading={index === 0 ? "eager" : "lazy"}
                    loading={"eager"}
                  />
                  {/* </picture> */}
                </CarouselItem>
              )
            )}
          </CarouselContent>
          <CarouselPrevious />
          <CarouselNext />
          <CarouselIndicators />
        </Carousel>
      </div>
    </div>
  );
};

export default MultipleImages;
