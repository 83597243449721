"use client";

import React from "react";
import Link from "next/link";
import { useTranslation } from "react-i18next";
import { Icon } from "@iconify/react";
import { handleResizeImageUrl } from "@/utils";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Button } from "@/components/ui/button";

type NavigationType = {
  username: string;
  account: any;
};

const Navigation: React.FC<NavigationType> = ({
  username = "",
  account = {},
}) => {
  const { t } = useTranslation();
  return (
    <nav className="nav-product w-full h-[4rem] bg-white flex justify-center items-center">
      <div className="nav-product-wrapper container-product flex justify-between items-center w-full">
        <div className="nav-product-merchant ml-2 flex items-center">
          <Link
            href={process.env.NODE_ENV === "development" ? `/${username}` : `/`}
            className="flex items-center font-[500]"
          >
            {account?.logo?.url && (
              <img
                alt="logo"
                src={handleResizeImageUrl(account.logo.url, 300)!}
                width="29"
                height="29"
                className="rounded-full h-[29px] mr-2.5"
              />
            )}
            <h3 className="m-0 text-[16px] font-[600]">{account.name}</h3>
          </Link>
        </div>

        <div className="nav-product-right">
          <Popover>
            <PopoverTrigger asChild>
              <Button variant="ghost">
                <Icon icon="mage:dots" className="text-lg" />
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-1 bg-white flex flex-col text-gray-500">
              <a href={`/portal`}>
                <Button variant="link" className="text-[12px]">
                  {t("word.enterPortalCustomer")}
                </Button>
              </a>
            </PopoverContent>
          </Popover>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
