"use client";

import './style.scss';

import React, { Fragment, useEffect, useState } from 'react';
import Axios from 'axios';
import InnerHTML from 'dangerously-set-html-content';
import { useTranslation } from 'react-i18next';
import { REGEX_UUID } from '../../../utils';
import BrokenLink from '../../broken-link';
import { conversionPageView } from '@/utils/conversion-settings';
import LoadingAnimation from '../../loading-animation';
import UserDispatcher from '@/components/dispatcher/user';

const LandingPage = ({ id, currentUser }) => {
  const { t } = useTranslation();
  const [landingPage, setLandingPage] = useState({});
  const [conversionSetting, setConversionSetting] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const identifier = { id: null, link: null };

  if (REGEX_UUID.test(id)) {
    identifier.id = id;
  } else {
    identifier.link = id;
  }

  useEffect(() => {
    if (currentUser) {
      Axios.get(
        `${process.env.NEXT_PUBLIC_MAYAR_USERKV_S3_ENDPOINT}/landing-page/${
          identifier.id ? 'data' : 'data-link'
        }/${process.env.NEXT_PUBLIC_MAYAR_FOLDER_ENV}/${
          identifier.id || identifier.link || currentUser?.homePagePath?.split('/')[1]
        }.json`,
      )
        .then((res) => {
          setLandingPage(res.data);

          if (res.data?.conversionSettings?.length > 0) {
            setConversionSetting(res.data.conversionSettings[0]);
          }
        })
        .catch(() => setError(true))
        .finally(() => setLoading(false));
    } else if (!currentUser) {
      setLandingPage({ errorCode: 404 });
    }
  }, [currentUser]);

  useEffect(() => {
    conversionPageView(conversionSetting);
  }, [conversionSetting]);

  if (error || !currentUser) return <BrokenLink isServerError />;

  if (loading)
    return (
      <div className="responsivearticle">
        <LoadingAnimation responsivearticle="true" />;
      </div>
    );

  const { metaTitle, htmlResult, status, user, name, type } = landingPage;

  if (
    status === 'inactive' ||
    status === 'archived' ||
    type === 'BASIC' ||
    user?.id !== currentUser?.id ||
    landingPage.errorCode === 404
  ) {
    return <BrokenLink message={t('alert.link404')} />;
  }

  return (
    <Fragment>
      <UserDispatcher user={currentUser}/>
      <InnerHTML html={htmlResult} />
    </Fragment>
  );
};

export default LandingPage;
