"use client";
import React, { FC } from "react";
import { get } from "lodash";
import Autoplay from "embla-carousel-autoplay";
import Image from "next/image";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { GQL_GET_FUNDRAISINGS_BY_USERNAME } from "@/gqls";
import {
  getFundraisingSubTypePath,
  createInternalPath,
  getCoverImage,
} from "@/utils";
import {
  Carousel,
  CarouselContent,
  CarouselIndicators,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import LoadingAnimation from "@/components/loading-animation";
import Link from "next/link";
import { CardImage } from "@/components/ui/card";
import { IPaymentLink } from "@/composables/paymentlink.type";
import FundraisingMenu from "@/components/fundraising/menu";
import { useAppSelector } from "@/lib/redux/hook";

interface FundraisingGalleryProps {}

interface CarouselItem {
  src: string;
  alt: string;
  link: string;
  width: number;
  height: number;
}

const FundraisingGallery: FC<FundraisingGalleryProps> = () => {
  const user = useAppSelector((state) => state.user);
  const { t } = useTranslation();
  const { isWaqafVisible = false, isQurbanVisible = false } =
    user?.fundraisingSettings ?? {};
  const mainPrograms = user?.fundraisingSettings?.mainPrograms
    ? JSON.parse(user.fundraisingSettings.mainPrograms)
    : [];

  const gparam = {
    pageSize: 3,
    page: 1,
    status: "active",
    username: user.paymeLink,
    search: {
      ...(!isWaqafVisible || !isQurbanVisible
        ? {
            subType: [
              ...(!isWaqafVisible
                ? [{ operator: "not", value: "FUNDRAISING_WAQAF" }]
                : []),
              ...(!isQurbanVisible
                ? [{ operator: "not", value: "FUNDRAISING_QURBAN" }]
                : []),
            ],
          }
        : { subType: [{ operator: "not", value: "" }] }),
    },
  };

  const variablesMainPrograms = {
    ...gparam,
    ...(mainPrograms.length > 0
      ? {
          searchAny: {
            id: mainPrograms.map(({ id }: { id: string }) => ({
              operator: "eq",
              value: id,
            })),
          },
        }
      : { sortField: "target", sortDirection: "DESC" }),
  };

  const variablesOverSoon = {
    ...gparam,
    sortField: "expiredAt",
    sortDirection: "ASC",
    search: {
      ...gparam.search,
      expiredAt: [{ operator: "notEmpty" }],
    },
  };

  const {
    loading: loadingBiggest,
    error: errorBiggest,
    data: dataBiggest,
  } = useQuery(GQL_GET_FUNDRAISINGS_BY_USERNAME, {
    context: { public: true },
    variables: variablesMainPrograms,
    fetchPolicy: "network-only",
  });

  const {
    loading: loadingOverSoon,
    error: errorOverSoon,
    data: dataOverSoon,
  } = useQuery(GQL_GET_FUNDRAISINGS_BY_USERNAME, {
    context: { public: true },
    variables: variablesOverSoon,
    fetchPolicy: "network-only",
  });

  const biggestFundraisings: IPaymentLink[] = get(
    dataBiggest,
    "getFundraisingsByUsername.items"
  );
  const overSoonFundraisings: IPaymentLink[] = get(
    dataOverSoon,
    "getFundraisingsByUsername.items",
    []
  );
  const fundraisingSettings = user.fundraisingSettings;

  if (errorBiggest) return null;
  if (errorOverSoon) return null;

  const setCarouselItems = (items: IPaymentLink[]): CarouselItem[] => {
    let newItems = items;

    if (mainPrograms.length > 0) {
      newItems = [];

      mainPrograms.forEach(({ id }: { id: string }) => {
        items.forEach((obj) => {
          if (id === obj.id) {
            newItems.push(obj);
          }
        });
      });
    }

    return newItems.map(({ coverImage, multipleImage, link, subType }) => ({
      src: getCoverImage(coverImage, multipleImage, "md"),
      alt: "",
      link: createInternalPath(
        `/${getFundraisingSubTypePath(subType)}/v2/${link}`,
        user.paymeLink
      ),
      width: coverImage?.width,
      height: coverImage?.height,
    }));
  };

  return (
    <div>
      {/* TAMPILKAN 3 PROGRAM GALANG DANA DENGAN DONASI TERBANYAK / TERPOPULER */}
      {loadingBiggest ? (
        <div className="text-center">
          <LoadingAnimation three="true" />
        </div>
      ) : (
        <Carousel plugins={[Autoplay({ delay: 5000 })]} className="-mx-4">
          <CarouselContent className="ml-0">
            {setCarouselItems(biggestFundraisings)?.map(
              ({ src, link, width, height }: CarouselItem, index) => (
                <CarouselItem key={index} index={index} className="relative">
                  <Image
                    src={src}
                    alt=""
                    width={width}
                    height={height}
                    style={{
                      width: "100%",
                      height: "auto",
                      aspectRatio: width / height,
                      objectFit: "cover",
                    }}
                    quality={70}
                    priority={true}
                  />
                  <Link
                    href={link}
                    className="absolute top-0 left-0 w-full h-full"
                  />
                </CarouselItem>
              )
            )}
          </CarouselContent>
          <CarouselPrevious />
          <CarouselNext />
          <CarouselIndicators />
        </Carousel>
      )}

      <FundraisingMenu fundraisingSettings={fundraisingSettings} />

      {loadingOverSoon && (
        <div className="text-center">
          <LoadingAnimation three="true" />
        </div>
      )}

      {overSoonFundraisings?.length > 0 && (
        <div className="mt-5 text-center">
          <h2 className="mb-4 font-semibold text-lg">
            {t("fundraising.almostOver")}
          </h2>

          <Carousel>
            <CarouselContent className="ml-0 gap-3">
              {overSoonFundraisings.map(
                ({ link, id, coverImage, multipleImage, subType }, index) => {
                  const imageSrc = getCoverImage(
                    coverImage,
                    multipleImage,
                    "md"
                  );

                  return (
                    <CarouselItem
                      key={id}
                      className="relative w-[225px] h-[90px] sm:w-[320px] sm:h-[125px] md:w-[380px] md:h-[135px]"
                      index={index}
                      isSwiperMode={true}
                    >
                      <CardImage
                        src={imageSrc}
                        alt="Cover Image Over Soon"
                        className="rounded-md w-[225px] h-[90px] sm:w-[320px] sm:h-[125px] md:w-[380px] md:h-[135px]"
                      />
                      <Link
                        href={createInternalPath(
                          `/${getFundraisingSubTypePath(subType)}/v2/${link}`,
                          user.paymeLink
                        )}
                        className="absolute top-0 left-0 w-full h-full"
                      ></Link>
                    </CarouselItem>
                  );
                }
              )}
            </CarouselContent>
          </Carousel>
        </div>
      )}
    </div>
  );
};

export default FundraisingGallery;
